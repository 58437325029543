.typing-indicator-container {
  background: linear-gradient(to bottom, transparent, rgba(249, 250, 251, 0.9) 20%);
  z-index: 10;
}

.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.typing-indicator span {
  height: 10px;
  width: 10px;
  background-color: #3498db;
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}