.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    marginRight: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity 0.3s ease-in-out;
  }

  .absolute {
    position: absolute;
  }

  .z-50 {
    z-index: 50;
  }

  .quill-container .ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  
  .quill-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    user-select: text !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
  -ms-user-select: text !important;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  /* Add any additional styles you need */