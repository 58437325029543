@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200
    border border-gray-200 shadow-sm
    hover:shadow-md hover:translate-y-[-1px]
    active:translate-y-[1px] active:shadow-sm
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
  }

  .btn-primary {
    @apply btn bg-blue-500 text-white border-blue-600
    hover:bg-blue-600;
  }

  .btn-secondary {
    @apply btn bg-gray-100 text-gray-700
    hover:bg-gray-200 px-1 py-0.5;
  }

  .btn-tips {
    @apply btn bg-red-500 text-white border-red-600
    hover:bg-red-600;
  }
}
